<template>
  <div class="commission childpool-wrap">
    <CCard>
      <CCardBody>
        <CRow class="p-1">
          <CCol col="12" xl="5" class="p-1 pl-3 pr-3">
            <CInput
            :label="$t('COMMISSION_PAGE_LABEL_PARTNER_ID')"
            horizontal
            :value="partnerSelected.id"
            readonly
            />
          </CCol>
          <CCol col="12" xl="7" class="p-1 pl-3 pr-3">
            <CInput
            :label="$t('COMMISSION_PAGE_LABEL_PARTNER_NAME')"
            horizontal
            :value="partnerSelected.name"
            readonly
            />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardBody>
        <div class="font-weight-bold m-1">{{$t("COMMISSION_PAGE_TITLE_COMMISSION")}}</div>
        <CRow>
          <CCol col="12">
            <CRow
                    class="custom-input form-"
                    alignVertical="center"
                    v-for="(commission, index) in commissions"
                    :key="index"
            >
              <CCol col="12" xl="5">
                <ValidationProvider
                        :name="commission.platform + '\'s commission'"
                        rules="required|max_value:100|min_value:0"
                >
                  <div slot-scope="{ errors }">
                    <CRow alignVertical="center">
                      <CCol class="text-uppercase" sm="4">{{commission.platform}} <span v-if="commission.platform ==='ETH'">({{commission.symbol}})</span></CCol>
                      <CCol sm="8">
                        <currency-input
                                class="input-field"
                                v-model="commission.commission"
                                :precision="3"
                                :currency="{ suffix: '%' }"
                        />
                        <span class="error-msg-wrap">
                              <span class="error-msg">{{errors[0]}}</span>
                            </span>
                      </CCol>
                    </CRow>
                  </div>
                </ValidationProvider>
              </CCol>
              <CCol col="12" xl="7" class="pt-1">
                <ValidationProvider
                        :name="commission.platform === 'ETH' ?
                        commission.platform + '('+ commission.symbol.toUpperCase() + ')' : commission.platform"
                >
                  <div slot-scope="{ errors }">
                    <CRow alignVertical="center">
                      <CCol class sm="2">{{$t("COMMISSION_PAGE_LABEL_REWARD_ADDRESS")}}</CCol>
                      <CCol sm="10">
                        <input
                                class="input-field text-left"
                                max-length="12"
                                :readonly="!!commission.id"
                                v-model="commission.reward_address"
                        />
                        <span class="error-msg-wrap">
                              <span class="error-msg">{{errors[0]}}</span>
                            </span>
                      </CCol>
                    </CRow>
                  </div>
                </ValidationProvider>
              </CCol>
            </CRow>
          </CCol>
          <CCol col="12" class="mt-2 text-right">
            <CButton class="pl-4 pr-4" color="primary" @click="onSave">{{$t("COMMISSION_PAGE_BUTTON_SAVE")}}</CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardBody>
        <div class="font-weight-bold m-1">{{$t("COMMISSION_PAGE_TITLE_REQUEST")}}</div>
        <CDataTable
          class="mb-0"
          outlined
          striped
          hover
          :items="listRequests"
          :fields="tableRequest"
          head-color="light"
          no-sorting
        >
          <td class="text-center" slot="order" slot-scope="{ item, index }">
            {{ index + 1 }}
          </td>
          <td class="text-center" slot="time" slot-scope="{ item }">
            {{ item.updated_at | moment("YYYY-MM-DD HH:mm") }}
          </td>
          <td class="text-center" slot="platform" slot-scope="{ item }">
            {{ item.platform }}
          </td>
          <td class="" slot="address" slot-scope="{ item }" >
            {{ item.reward_address }}
          </td>
          <td class="text-center" slot="status" slot-scope="{ item }">
            {{ renderStatus(item.status) }}
          </td>
          <td class="text-center" slot="action" slot-scope="{ item }">
            <CButton v-if="item.status===1" class="btn-confirm" @click="oppenModal(item)">
              {{$t("COMMISSION_PAGE_BUTTON_CONFIRM")}}
            </CButton>
            <CButton v-if="item.status===2" class="btn-confirm" @click="oppenModal(item)">
              {{$t("COMMISSION_PAGE_BUTTON_RESEND")}}
            </CButton>
          </td>
        </CDataTable>
        <div>
          <CPagination
            v-if="pagesR>1"
            :activePage.sync="activePageR"
            :pages="pagesR"
            align="end"
            @update:activePage="pushPageRequest"
          />
        </div>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardBody v-if="this.hasView === true">
        <div class="font-weight-bold m-1">{{$t("COMMISSION_PAGE_TITLE_HISTORY")}}</div>
        <CDataTable
          class="mb-0"
          outlined
          striped
          hover
          :items="transactions"
          :fields="tableHeaders"
          head-color="light"
          no-sorting
        >
          <td class="text-center" slot="order" slot-scope="{ item, index }">{{ index + 1 }}</td>
          <td class="text-center" slot="platform" slot-scope="{ item }">{{ item.platform }}</td>
          <td class="text-center" slot="commission" slot-scope="{ item }">{{ item.commission }}</td>
          <td
            class="text-center"
            slot="updateAt"
            slot-scope="{ item }"
          >{{ item.updated_at | moment("YYYY-MM-DD HH:mm") }}</td>
          <td class="text-center" slot="updated_by_user_name" slot-scope="{ item }">{{ item.updated_by_user_name }}</td>
        </CDataTable>
        <div>
          <CPagination
            v-if="pages>1"
            :activePage.sync="activePage"
            :pages="pages"
            align="end"
            @update:activePage="pushPage"
          />
        </div>
      </CCardBody>
    </CCard>
    <CModal id="commissionModal" :show.sync="commissionModal" :centered="true">
      <template #header>
        <h5 class="modal-title">{{$t("COMMISSION_PAGE_TITLE_MODAL_CONFIRMATION")}}</h5>
        <CButtonClose @click="commissionModal = false" />
      </template>
      <div>
        <p class="text-modal" v-if="selectItem.status === 1">{{$t("COMMISSION_PAGE_TEXT_MODAL_CONFIRM_REQUEST")}}</p>
        <p class="text-modal" v-if="selectItem.status === 2">{{$t("COMMISSION_PAGE_TEXT_MODAL_RESEND_EMAIL")}}</p>
        <p class="text-modal">{{$t("COMMISSION_PAGE_TEXT_MODAL_NEW_ADDRESS")}} <strong>{{ confirmAddress }}</strong></p>
      </div>

      <template #footer>
        <CButton
          class="cancel-btn"
          @click="commissionModal = false"
          color="dark"
          variant="outline"
        >{{$t("COMMISSION_PAGE_BUTTON_MODAL_CANCEL")}}</CButton>
        <CButton v-if="selectItem.status === 1" class="submit-btn" @click="confirmRequest">
          {{$t("COMMISSION_PAGE_BUTTON_MODAL_CONFIRM")}}
        </CButton>
        <CButton v-if="selectItem.status === 2" class="submit-btn" @click="resendRequest">
          {{$t("COMMISSION_PAGE_BUTTON_MODAL_RESEND")}}
        </CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import endpoints from "@/constants/endpoints";
import { mapState, mapActions } from "vuex";
import {compare} from "@/utils/lib"
import responseHelper from '@/utils/response-helper';
export default {
  name: "Commission",
  data() {
    return {
      tableHeaders: [
        { key: "order", label: "#", _classes: "text-center" },
        { key: "platform", label: this.$t("COMMISSION_PAGE_LABEL_PLATFORM"), _classes: "text-center" },
        { key: "commission", label: this.$t("COMMISSION_PAGE_LABEL_COMMISSION"), _classes: "text-center" },
        { key: "updateAt", label: this.$t("COMMISSION_PAGE_LABEL_UPDATED_AT"), _classes: "text-center" },
        { key: "updated_by_user_name", label: this.$t("COMMISSION_PAGE_LABEL_UPDATED_BY"), _classes: "text-center" }
      ],
      transactions: [],
      partnerId: "",
      commissions: [],
      limit: 5,
      pages: 0,
      pagesR: 0,
      activePage: 1,
      activePageR: 1,
      tableRequest: [
        { key: 'order', label: '#', _classes: 'text-center'},
        { key: 'time', label: this.$t("COMMISSION_PAGE_LABEL_TIME"), _classes: 'text-center'},
        { key: 'platform', label: this.$t("COMMISSION_PAGE_LABEL_PLATFORM"), _classes: 'text-center'},
        { key: 'address', label: this.$t("COMMISSION_PAGE_LABEL_NEW_ADDRESS")},
        { key: 'status', label: this.$t("COMMISSION_PAGE_LABEL_STATUS"), _classes: 'text-center'},
        { key: 'action', label: this.$t("COMMISSION_PAGE_LABEL_ACTION"), _classes: 'text-center'},

      ],
      listRequests: [],
      totalRequest: 0,
      commissionModal: false,
      confirmAddress: '',
      selectItem: [],
      hasView: false
    };
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    offsetR() {
      return (this.activePageR - 1) * this.limit;
    },
    ...mapState({
      partnerSelected: state => state.partner.partnerSelected,
      userPermission: state => state.auth.userPermission,
    })
  },
  async mounted() {
    this.partnerID = this.$route.params.id;
    this.getCommissions();
    this.getCommissionHistory();
    const datas = await this.getListRequest();
    this.listRequests = datas.items;
    if(this.partnerID!==this.partnerSelected.id){
      const currentPartner = await this.$http.get(endpoints.getPartner(this.partnerID));
      await this.getSelectedPartner(currentPartner.data);
    }
    await this.checkPermission();
  },
  methods: {
    ...mapActions(["getSelectedPartner"]),
    async getListRequest() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offsetR
        };
        let result = await this.$http.get(
          endpoints.getAllRequestChangeReward(this.partnerID),
          {
            params: params
          }
        );
        this.totalRequest = result.data.total;
        return result.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    async getCommissions() {
      try {
        let result = await this.$http.get(
          endpoints.getCommission(this.partnerID)
        );
        if(result.data){
            this.commissions = result.data.items.slice().sort(compare);
        }
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    async getCommissionHistory() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset
        };
        let history = await this.$http.get(
          endpoints.getCommissionHistory(this.partnerID),
          {
            params: params
          }
        );
        if(history.data){
            this.transactions = history.data.items.slice().sort();
            this.pages = Math.ceil(history.data.total / this.limit);
        }
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    async pushPage() {
      let transactions = await this.getCommissionHistory();
      if (!transactions) {
        return;
      }

      this.transactions = transactions.items.slice();
    },

    async pushPageRequest() {
      let result = await this.getListRequest();
      if (!result) {
        return;
      }
      this.listRequests = result.items.slice();
    },

    async onSave() {
      try {
        let items = this.commissions.map(el => {
          return {
            id: el.id,
            platform: el.platform,
            commission: el.commission,
            reward_address: el.reward_address,
            staking_platform_id: el.staking_platform_id
          };
        });

        let result = await this.$http.post(
          endpoints.updateCommission(this.partnerID),
          {
            items: items
          },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        );

        this.$notify({
          group: "notifications",
          type: "success",
          title: this.$t("COMMISSION_PAGE_TITLE_NOTIFY_SUCCESS"),
          text: this.$t("COMMISSION_PAGE_TEXT_NOTIFY_SUCCESS"),
          duration: 1000
        });
      } catch (err) {
        console.log(err);
        this.$notify({
          group: "notifications",
          type: "error",
          title: this.$t("COMMISSION_PAGE_TITLE_NOTIFY_ERROR"),
          text: responseHelper.getErrorMessage(err),
          duration: 1000
        });
      }
    },

    renderStatus(e){
      var sts_name = '';
      if(e===1){
        sts_name = 'NEW'
      }
      if(e===2){
        sts_name = 'CONFIRMING'
      }
      if(e===3){
        sts_name = 'DONE'
      }
      return sts_name;
    },

    removeDraft(val){
      const newVal = [];
      val.map(function(e){
        if(e.status!=0){
          newVal.push(e);
        }
      });
      return newVal;
    },
    async confirmRequest(){
      const item = this.selectItem;
      try{
        let data = {
          status: 2
        }
        const param = {
          partner_id: item.partner_id,
          commission_id: item.partner_commission_id,
          id: item.id
        }
        const {partner_id, commission_id, id} = param;
        const result = await this.$http.put(endpoints.confirmCommissionRequest(partner_id, commission_id, id), data);
        if (result.data) {
            this.$notify({
                group: 'notifications',
                type: 'success',
                title: this.$t("COMMISSION_PAGE_TITLE_NOTIFY_SUCCESS"),
                text: ''
            });
          this.pushPageRequest();
        }
      }
      catch (err){
        this.$notify({
          group: "notifications",
          type: "error",
          title: this.$t("COMMISSION_PAGE_TITLE_NOTIFY_ERROR"),
          text: responseHelper.getErrorMessage(err),
          duration: 1000
        });
      }
      this.commissionModal = false;
    },
    async resendRequest(){
      const item = this.selectItem;
      try{
        const param = {
          partner_id: item.partner_id,
          commission_id: item.partner_commission_id,
          id: item.id
        }
        const {partner_id, commission_id, id} = param;
        const result = await this.$http.post(endpoints.resendConfirmCommissionRequest(partner_id, commission_id, id));
        if (result.data) {
            this.$notify({
                group: 'notifications',
                type: 'success',
                title: 'SUCCESS',
                text: ''
            });
          this.pushPageRequest();
        }
      }
      catch (err){
        this.$notify({
          group: "notifications",
          type: "error",
          title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
          text: responseHelper.getErrorMessage(err),
          duration: 1000
        });
      }
      this.commissionModal = false;
    },
    oppenModal(item){
    this.commissionModal = true;
    this.confirmAddress = item.reward_address;
    this.selectItem = item;
    },
    checkPermission(){
      if(this.userPermission.length > 0){
         this.userPermission.forEach(items =>{
            if(items.name === 'VIEW_COMMISSION_HISTORY_SETTING'){
               this.hasView = true
            }
         })
      }
    }
  },
};
</script>
<style lang="scss">
.commission{
   .form-control {
    text-align: left;
  }
  .form-group {
    margin-bottom: 0;
  }

  .custom-input {
    padding-top: 4px;
    .input-field {
      padding: 6px 12px;
      width: 100%;
      border: 1px solid #d8dbe0;
      border-radius: 0.25rem;
      text-align: left;
    }
  }
  .error-msg{
    color: red;
    padding-left: 0;
  }
/*  Phat css*/
  .card{
    .card-body{
      .col-form-label{
        text-align: right;
        padding-right: 15px;
        line-height: 25px;
        color: #3E4B65;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }
      .text-uppercase{
        text-align: right;
        padding-right: 15px;
        line-height: 25px;
        color: #3E4B65;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }
      input[type='text']{
        width: 100%;
      }
      .btn-primary{
        min-width: 146px;
        height: 36px;
        border-radius: 4px;
        background-color: #3B4B7E;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        border: none;
        outline: none !important;
        -webkit-box-shadow: none;
        box-shadow: none;
        text-align: center;
      }
    }
  }
}
.err-msg{
  font-size: 14px;
  font-weight: 300;
  line-height: 28px;
  color: #ff0000;
  margin-bottom: 10px;
  padding-left: 0;
}
.btn-confirm{
  padding: 0;
  outline: none;
  color: #0d66af;
  cursor: pointer;
}
#commissionModal /deep/{
  .modal-content{
    width: 85%;
    .modal-header {
      padding: 10px 15px;
    }
    .text-modal {
      margin-bottom: 5px;
      strong{
        color: #3A4A7F;
        font-weight: 500;
        word-break: break-word;
      }
    }
    .modal-footer {
      // border: none;
      justify-content: flex-end;
      .submit-btn {
        background-color: #38a3ff;
        color: #ffffff;
        min-width: 85px;
        font-weight: 500;
        font-size: 14px;
        margin: 0 0 0 8px;
        outline: none !important;
      }
      .cancel-btn {
        min-width: 85px;
        border: 2px solid #CBCBCB;
        color: #657187;
        font-weight: 500;
        font-size: 14px;
        margin: 0;
        outline: none !important;
        &:hover{
          background-color: #fff
        }
      }
    }
  }
}
</style>
